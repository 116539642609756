<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <filter-role
          class="mr-2"
          placeholder="Role..."
          style="width: 300px"
          v-model:value="roles"
        ></filter-role>
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="ml-2" type="primary" title="cari" @click="emit('search', filters)">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-2"
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Tambah Grup Area"
          @click="emit('add')"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="emit('fetchXlsx')"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterRole from '@/components/filter/FilterRole'
import { reactive, toRefs } from 'vue'

export default {
  name: 'MFilter',
  components: {
    FilterRole,
  },
  emits: ['search', 'fetchXlsx', 'add'],
  setup(props, { emit }) {
    const filters = reactive({
      q: null,
      roles: [],
    })

    return { filters, ...toRefs(filters), emit }
  },
}
</script>

<style scoped>
.row {
  /* border: 1px dotted gray; */
}
</style>
