export default {
  name: [{ required: true, message: 'Nama distributor tidak boleh kosong!' }],
  email: [
    {
      required: true,
      message: 'Email tidak boleh kosong!',
    },
    {
      type: 'email',
      message: 'Format email salah',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Nomor Telepon tidak boleh kosong!',
    },
    {
      max: 12,
      min: 0,
      message: 'Maximal nomor telepon 12 angka',
    },
    {
      pattern: /^[0-9]*$/,
      message: 'Nomor Telepon tidak boleh memuat karakter selain angka',
    },
  ],
  fullname: [
    {
      required: true,
      message: 'Nama tidak boleh kosong!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Password tidak boleh kosong!',
    },
  ],
  role_id: [
    {
      required: true,
      type: 'number',
      message: 'Role tidak boleh kosong!',
    },
  ],
  group_role_id: [
    {
      required: true,
      type: 'number',
      message: 'Group Mapping tidak boleh kosong!',
    },
  ],
  active: [
    {
      required: true,
      type: 'boolean',
      message: 'Status tidak boleh kosong!',
    },
  ],
}
