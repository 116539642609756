<template>
  <!-- modal -->
  <a-modal
    :visible="show"
    :destroy-on-close="true"
    width="700px"
    :mask-closable="false"
    @cancel="emit('cancel')"
    :title="title"
    v-bind="$attrs"
  >
    <!-- <pre>{{ r }}</pre> -->
    <!-- <pre>{{ $attrs }}</pre> -->
    <a-form
      class="myform"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      ref="formRef"
      :rules="rules"
      :model="record"
    >
      <a-form-item label="Nama Group Mapping" name="name">
        <a-input style="width: 300px;" v-model:value="name" :disabled="readOnly"></a-input>
      </a-form-item>

      <a-form-item label="Role" name="role_id">
        <filter-role
          :mode="null"
          v-model:value="role_id"
          :disabled="readOnly"
          show-search
        ></filter-role>
      </a-form-item>

      <a-form-item label="Parent Group" name="parent_id">
        <filter-group-role
          :mode="null"
          v-model:value="parent_id"
          :disabled="readOnly"
          show-search
          :display-label="atasan"
        ></filter-group-role>
      </a-form-item>

      <a-form-item label="Area" name="group_role_areas">
        <filter-area
          v-model:value="group_role_areas"
          :join-options="areas"
          :disabled="readOnly"
        ></filter-area>
      </a-form-item>

      <a-form-item label="Status" name="active">
        <a-input v-if="readOnly" v-model:value="active" :disabled="readOnly"></a-input>
        <a-radio-group v-else v-model:value="record.active">
          <a-radio :value="true">Aktif</a-radio>
          <a-radio :value="false">Non Aktif</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-alert v-if="errorMessage" :message="errorMessage" type="error" />
      <!-- <pre>{{ record }}</pre> -->
    </a-form>
    <template #footer>
      <a-button key="submit" @click="submit" type="primary" v-if="!readOnly">Simpan</a-button>
      <a-button key="cancel" @click="emit('cancel')">Cancel</a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, watch, toRefs, ref, onMounted, reactive } from 'vue'

import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterRole from '@/components/filter/FilterRole'
import FilterGroupRole from '@/components/filter/FilterGroupRole'
import FilterArea from '@/components/filter/FilterArea'

import rules from './rules.js'

export default {
  components: {
    FilterRole,
    FilterGroupRole,
    FilterArea,
  },
  props: {
    show: [Boolean],
    state: {
      type: Object,
      default: () => {
        title: ''
      },
    },
  },
  emits: ['cancel', 'refresh'],
  setup(props, { emit }) {
    const title = toRefs(props).state.value.title
    const r = toRefs(props).state.value.record
    const areas = ref([])
    const readOnly = toRefs(props).state.value.readOnly

    const record = reactive({
      id: null,
      name: null,
      role_id: null,
      parent_id: null,
      group_role_areas: [],
      active: null,
      atasan: null,
    })
    const formRef = ref('')

    const active = computed(() => (props.state.record.active ? 'Aktif' : 'Non Aktif'))

    const errorMessage = ref(null)

    const submit = async () => {
      // console.log({ record })
      try {
        await formRef.value.validate()
      } catch (error) {
        console.log(error)
        return
      }

      errorMessage.value = null
      apiClient
        .post(`/api/group-roles${record.id ? `/${record.id}?_method=PUT` : ''}`, record)
        .then(
          ({ data }) =>
            new Promise((resolve, reject) => {
              {
                apiClient
                  .post('/api/group-roles/' + data.id + '/assign-area', {
                    areas: record.group_role_areas,
                  })
                  .then(({ data }) => {
                    resolve(data)
                  })
                  .catch(e => {
                    reject(e)
                  })
              }
            }),
        )
        .catch(e => {
          message.error('Gagal Menambah Data!')

          if (e.response && Array.isArray(e.response.data) && e.response.data[0].field) {
            errorMessage.value = e.response.data[0].message
          }
        })
        .then(result => {
          console.log({ result })
          emit('refresh')
          emit('cancel')
          message.success('Berhasil simpan!')
        })
    }

    if (r && r.id) {
      {
        apiClient
          .get(`/api/group-roles/${r.id}?expand=group_role_areas`)
          .then(({ data }) => {
            record.id = data.id
            record.name = data.name
            record.role_id = data.role_id
            record.parent_id = data.parent_id
            record.group_role_areas = data.group_role_areas.map(i => i.id)
            areas.value = data.group_role_areas.map(i => ({ id: i.area_id, name: i.area_name }))
            record.active = data.active
            record.atasan = data.atasan
          })
          .catch(e => {
            console.error(e)
          })
      }
    }

    return {
      emit,
      title,
      submit,
      record,
      ...toRefs(record),
      r,
      readOnly,
      active,
      errorMessage,
      formRef,
      rules,
      areas,
    }
  },
}
</script>

<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
</style>
